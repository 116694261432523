import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.member', route: { name: 'member' } },
      ],
      listRoute: () => ({ name: 'member' }),
      afterCreateRoute: result => ({
        name: 'member-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['email']),
      pageTitleBase: () => 'module.member',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.person_name',
              maxlength: 100,
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            avatar: {
              label: 'data.avatar',
              type: 'photo',
              photoType: 'member',
              readonly: true,
            },
            email: {
              email: true,
              readonly: true,
              maxlength: 100,
              copyable: true,
              label: 'data.email',
              type: 'text',
            },
            phone: {
              label: 'data.phone',
              type: 'text',
              copyable: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            register_type: {
              label: 'member.data.register_type',
              readonly: true,
              type: 'text',
            },
            locked: {
              label: 'member.data.locked',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
          },
        },

        // SSO
        {
          title: 'SSO',
          create: () => this.vm?.$store.getters['token/isMaintainer'],
          data: {
            oauth_resource_id_google: {
              label: 'Google Resource ID',
              type: 'text',
              readonly: true,
              copyable: true,
            },
            oauth_resource_id_facebook: {
              label: 'Facebook Resource ID',
              type: 'text',
              readonly: true,
              copyable: true,
            },
            oauth_resource_id_line: {
              label: 'Line Resource ID',
              type: 'text',
              readonly: true,
              copyable: true,
            },
            oauth_resource_id_apple: {
              label: 'Apple Resource ID',
              type: 'text',
              readonly: true,
              copyable: true,
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            'info.birthday': {
              label: 'member.data.birthday',
              type: 'time',
              format: 'date',
              clearable: true,
            },
            offset: {
              type: 'offset',
            },
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
      dataAction: {
        delete: false,
      },
    }
  }
}

export default new formConfig()
